jQuery(function ($) {
    "use strict";
    $('#header .header-switch-lang .current-lang').on('click', function (e) {
        e.preventDefault();
        $('#header .header-switch-lang .header-other-lang').slideToggle('slow');
    });

    $('#btn-mobile').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('#header .header-nav-wrap').toggleClass('active');
        $('#header').toggleClass('active');
    });

    $('.header-nav>ul.menu>li.menu-item-has-children>a').on('click', function(e){
        e.preventDefault();
    });

    $('.carousel-1').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><svg width="15" height="15" viewBox="0 0 5 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M4.9693 1.44L4.0293 0.5L0.0292969 4.5L4.0293 8.5L4.9693 7.56L1.91596 4.5L4.9693 1.44Z"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Next"><svg width="15" height="15" viewBox="0 0 5 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M0.969297 0.5L0.0292969 1.44L3.08263 4.5L0.0292969 7.56L0.969297 8.5L4.9693 4.5L0.969297 0.5Z"/></svg></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

});
